// Styling for the generate link element in the menus
.p-menuitem-link {
  padding: 16px;
  white-space: nowrap;
  // These transition properties match Tailwind transition-colors
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.p-menuitem-icon {
  margin-right: 4px;
}
