@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 14px;
}
/*
  By default, prime icons have a line-height of 1, which causes them to not
  center properly. Removing it allows them to center with other text.
*/
// .pi {
//   line-height: inherit !important;
// }
html {
  font-size: 14px;
}

.p-dialog.full-screen {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;

  .p-dialog-header {
    border-radius: 0;
  }
}

/* table style overrides */

.app-datatable .p-paginator .p-paginator-current {
  margin-left: auto;
}

.app-datatable .p-datatable.p-datatable-data .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1rem;
}
.app-datatable .p-datatable.p-datatable-data .p-paginator {
  padding: 1rem;
}

.app-datatable .p-datatable.p-datatable-data .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.5rem 1rem;
}

.app-datatable .p-datatable.p-datatable-data .p-datatable-tbody > tr > td {
  text-align: left;
  padding: 1rem 1rem;
}

.app-datatable .p-datatable.p-datatable-data .p-datatable-tbody > tr > td {
  cursor: pointer;
}
