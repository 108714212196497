.open {
  /* TODO: this color comes from the wireframes, should be updated to something in the theme eventually */
  /* The wireframes did not include any designs around what closed case chips should be colored - they are left gray. */
  background-color: hsl(142, 65%, 70%);
  color: hsl(142, 65%, 20%);
}

.closed {
  background-color: hsl(0, 65%, 80%);
  color: hsl(0, 65%, 20%);
}
